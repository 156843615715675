import { useEffect, useState } from "react";
import { TabPane, Table } from "reactstrap";
import PropTypes from 'prop-types';
import Loader from "../../layouts/loader/Loader";

const Contabilidad = ({ setRecibosPendientes }) => {

    // Preparamos los estados necesarios
    const [dataLoaded, setDataLoaded] = useState(false);
    const [dataApi, setDataApi] = useState([]);
    const [totalRecibos, setTotalRecibos] = useState(0);
    // Preparamos las variables necesarias
    const userSession = JSON.parse(sessionStorage.getItem('user'));
    const studentSession = JSON.parse(localStorage.getItem('student'));
    const opcionesFecha = { day: '2-digit', month: '2-digit', year: 'numeric' };

    // Peticiones a la API
    useEffect(() => {
        fetch(`${ process.env.REACT_APP_NODE_API }obtenerContabilidad?userEmail=${ userSession.profileObj.email }&empresaId=${ studentSession.empresaId }&id=${ studentSession.id }`, {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${ userSession.tokenId }`,
                'content-type': 'application/json'
            }
        })
        .then((response) => response.json())
        .then((actualData) =>  {
            
            // Calculamos el importe total de los recibos
            let total = 0;
            let pendientes = 0;

            actualData.forEach(element => {

                total += parseFloat(element.importe);
                const fechaActual = new Date();
                const fechaVTO = new Date(element.fechaVTO);
                // Revisamos si se cumple la condición para añadir el símbolo de exclamación
                if ((element.idEstado === 0 && fechaVTO < fechaActual) || (element.idEstado === 2 && fechaVTO < fechaActual)) {
                    pendientes++;
                }

            });

            if( pendientes >= 3 ) {
                setRecibosPendientes(true);
            }

            setDataApi( actualData );
            setTotalRecibos( total );
            setDataLoaded( true );
            
        })
        .catch(error => {

            sessionStorage.setItem('apiError', error);
            sessionStorage.removeItem('user');
            window.location.reload();

        });
    }, []);

    return(
        <TabPane tabId="6">
            {
                dataApi.length === 0 && !dataLoaded ? (
                    <Loader />
                ) : dataApi.length === 0 ? (
                    <p>No se han encontrado recibos.</p>
                ) : (
                    <>
                        <Table responsive borderless style={{ fontSize:"13px", borderCollapse: "separate", borderSpacing:"0 1em" }}>
                            <thead>
                                <tr>
                                    <th>ID Matrícula</th>
                                    <th>Grupo</th>
                                    <th>ID Recibo</th>
                                    <th>ID Factura</th>
                                    <th>Concepto</th>
                                    <th>Fecha VTO</th>
                                    <th>Fecha cobro</th>
                                    <th>Importe</th>
                                    <th>Forma de pago</th>
                                    <th>Estado</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataApi.map((r) => (
                                        <>
                                            <tr key={ r.numFactura } >
                                                {/* <td id={r.codigo} className ={r.Estado !== 'Pendiente' ? 'text-black-50 ': ''}>
                                                    <InputRecibo key={r.NUM_Recibo} id={r.NUM_Recibo} concepto={r.Concepto} neto={r.Neto} estado={r.Estado} onReciboChange={handleReciboChange} listadoRecibos={listadoRecibos} setListadoRecibos={setListadoRecibos}/>
                                                </td> */}
                                                <td className ={r.estado !== 'Pendiente' ? 'text-black-50': ''}>{ r.idMatricula }</td>
                                                <td className ={r.estado !== 'Pendiente' ? 'text-black-50': ''}>{ r.grupo }</td>
                                                <td className ={r.estado !== 'Pendiente' ? 'text-black-50': ''}>{ r.recibo }</td>
                                                <td className ={r.estado !== 'Pendiente' ? 'text-black-50': ''}>{ r.numFactura }</td>
                                                <td className ={r.estado !== 'Pendiente' ? 'text-black-50': ''}>{ r.concepto }</td>
                                                <td className ={r.estado !== 'Pendiente' ? 'text-black-50': ''}>{ new Intl.DateTimeFormat('es-ES', opcionesFecha).format(new Date(r.fechaVTO)) }</td>
                                                <td className ={r.estado !== 'Pendiente' ? 'text-black-50': ''}>{ r.fechaCobro === 'No cobrado' ? r.fechaCobro : new Intl.DateTimeFormat('es-ES', opcionesFecha).format(new Date(r.fechaCobro)) }</td>
                                                <td className ={r.estado !== 'Pendiente' ? 'text-black-50': ''}>{ r.importe } €</td>
                                                <td className ={r.estado !== 'Pendiente' ? 'text-black-50': ''}>{ r.formaPago }</td>
                                                <td className ={r.estado !== 'Pendiente' ? 'text-black-50': ''}>{ r.estado }</td>
                                            </tr>    
                                        </>
                                    ))
                                }
                            </tbody>
                        </Table >
                        <span className='text-danger'>Importe total de los recibos: {totalRecibos.toFixed(2)} €</span>
                    </>
                )
            }
        </TabPane>
    )
}

Contabilidad.propTypes = {
    setRecibosPendientes: PropTypes.any
};

export default Contabilidad;
