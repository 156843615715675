import { useState, useEffect, useContext} from 'react'
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'
import * as Icon from 'react-feather';
import ComponentCard from "../../layouts/containers/ComponentCard"
import DetallePeticion from './DetallePeticion';
import Loader from '../../layouts/loader/Loader'
import { AuthContext } from '../../context/authContext';

const PeticionMatricula = (props) => {

    const navigate = useNavigate()
    const student = JSON.parse(localStorage.getItem('student'))
    const userSession = JSON.parse(sessionStorage.getItem('user'))
    const { userOU } = useContext( AuthContext );
    const [dataApi, setDataApi] = useState('');
    const [balance, setBalance] = useState(0);

    const handleOnClick = () => {
       navigate("/matriculas/nueva")
    }
    useEffect(() => {
        const localStudent = JSON.parse(localStorage.getItem('student'))
        fetch(`${process.env.REACT_APP_NODE_API}peticionActiva?userEmail=${userSession.profileObj.email}&headquarter=${localStudent.empresaId}&id=${localStudent.id}`, {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${JSON.parse(sessionStorage.getItem('user')).tokenId}`,
                'content-type': 'application/json'
            }
        }
        )
            .then((response) => response.json())
            .then((actualData) => {

                setDataApi(actualData)

            })
    }, [])

    // Comprobamos si el usuario tiene balance negativo
    useEffect(() => {
        const localStudent = JSON.parse(localStorage.getItem('student'))
        fetch(
        `${process.env.REACT_APP_NODE_API}consultarBalanceGeneral?headquarter=${localStudent.empresaId}&id=${localStudent.id}&userEmail=${userSession.profileObj.email}`,
        {
            method: 'get',
            headers: {
            Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('user')).tokenId}`,
            'content-type': 'application/json',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0'
            },
        },
        )
        .then((response) => response.json())
        .then((actualData) => {
            setBalance(parseFloat(actualData.Balance));
        })
        .catch((error) => {
            sessionStorage.setItem('apiError', error);
            sessionStorage.removeItem('user');
            window.location.reload();
        });
    }, []);

    return (
        <ComponentCard title="Peticiones de matrícula">
            {
                dataApi === ''
                ? (<Loader />)
                : dataApi.length === 0
                    ? (
                        <>
                            {
                                props.missingData
                                    ? (<a className='text-danger'>Los datos del alumno no son correctos.</a>)
                                    : balance < 0
                                        ? (<a className='text-danger'>El alumno tiene un balance negativo de { balance.toString() } €.</a>)
                                        : props.recibosPendientes
                                            ? (<a className='text-danger'>El alumno tiene recibos pendientes o devueltos.</a>)
                                            : (<a>Este usuario no dispone de peticiones activas.</a>)
                            }
                            {
                                userOU.centro === 'ORIENTACIÓN' ? '' :  <Button className="float-end col-1 w-auto" color="success" disabled={ props.missingData || balance < 0 || props.recibosPendientes }>
                                <a>
                                    <Icon.Plus onClick={handleOnClick} />
                                </a>
                            </Button>
                            }
                           
                        </>
                    )
                    : <DetallePeticion idEnrollment={dataApi[0].NumMatricula} headquarter={student.empresaId} />
            }
        </ComponentCard >
    )
}
PeticionMatricula.propTypes = {
    recibosPendientes: PropTypes.any,
    missingData: PropTypes.any
};
export default PeticionMatricula