import React from "react";
import "./loader.scss";
import { Spinner } from "reactstrap";

const Loader = () => (
  <div style={{ height: '100px', width: '100%', display: 'flex', position: 'sticky', textAlign: 'center' }} >
    <div className="loading">
      <Spinner color="primary" />
    </div>
  </div >
);
export default Loader;
