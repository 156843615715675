import ReactTable from 'react-table-v6';
import PropTypes from 'prop-types'

const ListadoSolicitudes = (props) => {


    const translations = {
        previousText: '<',
        nextText: '>',
        loadingText: 'Cargando..',
        ofText: 'de',
        rowsText: 'líneas',
        noDataText: 'Datos no encontrados',
        pageText: 'Página',
    };


    return (

        <ReactTable
            {...translations}
            columns={[
                {
                    Header: 'Número matrícula',
                    accessor: 'num_matricula',
                },
                {
                    Header: 'Fecha',
                    accessor: 'fecha',
                },
                {
                    Header: 'Nombre',
                    accessor: 'nombre',
                },
                {
                    Header: 'Apellidos',
                    accessor: 'apellidos',
                },
                {
                    Header: 'Grupo',
                    accessor: 'nombre_grupo',
                },
                {
                    Header: 'Centro',
                    accessor: 'centro',
                },
                {
                    Header: 'Ciclo',
                    accessor: 'ciclo',
                },
                {
                    Header: 'Tipo',
                    accessor: 'curso',
                },
                {
                    Header: 'Estado',
                    accessor: 'estado',
                },
                {
                    Header: 'Acciones',
                    accessor: 'actions'
                }
            ]}
            data={props.dataApi}
            defaultPageSize={10}
            showPaginationBottom
            className="-striped -highlight mt-5 text-center"
        />



    )
}

ListadoSolicitudes.propTypes = {
    dataApi: PropTypes.any
};

export default ListadoSolicitudes