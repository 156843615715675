/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import React, { Suspense, useContext, useState } from 'react';
import { useRoutes } from 'react-router-dom';
import Themeroutes from './routes/Router';
import Loader from './layouts/loader/Loader';
import Login from './views/login/Login';
import FullLayout from './layouts/FullLayout';
import { AuthContext } from './context/authContext';

const App = () => {
  const { logoutGoogle } = useContext(AuthContext);
  const routing = useRoutes(Themeroutes);
  const userSession = JSON.parse(sessionStorage.getItem('user'));
  const [isVerified, setIsVerified] = useState(1);
  const [check, setCheck] = useState(0);

  let render = null;

  const onLoad = (verified) => {
    // Guardamos la URL a acceder en caso de requerir un login antes
    localStorage.setItem('lastPath', window.location.pathname);

    if (sessionStorage.getItem('user') === null) {
      if (sessionStorage.getItem('apiError') !== null) {
        render = <Login sesionExpired="true" />;
        sessionStorage.removeItem('apiError');
      } else if (check === 0) {
        render = <Login />;
      } else if (check === 1) {
        render = <Login sesionExpired="true" />;
      }
    } else if (userSession !== null) {
      if (verified === 0) {
        logoutGoogle();
        render = <Login sesionExpired="true" />;
        setIsVerified(1);
        setCheck(1);
      } else {
        render = <FullLayout>{routing}</FullLayout>;
      }
    }

    return render;
  };

  return (
    //<>{routing}</>
    <Suspense fallback={<Loader />}>
      <div className="ltr" dir="ltr">
        {onLoad(isVerified)}
      </div>
    </Suspense>
  );
};

export default App;
