import { useState } from 'react'
import PropTypes from 'prop-types'
import { ModalBody, ModalFooter, Modal, Button } from 'reactstrap'


const ModalOption = (props) => {
    const [modalState, setModalState] = useState(true)
    const handleModalState = () => {
        if (modalState === true) {
            setModalState(false)

        } else {
            setModalState(true)
        }
        props.sent(false)

    }

    const handleModalAccept = () => {
        props.load(true)
        setModalState(false)
        if (!props.boletin) {
            fetch(`${process.env.REACT_APP_URL_API}generarDocumento.php?alumnoId=${props.studentId}&empresa=${props.empresa}&cursoCode=${props.ciclo.codigo}&tipoDoc=SENDBOLNOT&idiomaCode=1`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${JSON.parse(sessionStorage.getItem('user')).tokenId}`,
                    'content-type': 'application/json'
                }
            }
            ).then(response => {
                if (response.status === 200) {
                    props.load(false)
                }
            })
        } else {
            setModalState(false)
            props.sendData()
        }
        props.sent(false)
    }
    return (
        <Modal isOpen={modalState} centered>
            <ModalBody>
                <h4 className="text-center mt-3">{props.header}</h4>
            </ModalBody>
            <ModalFooter>
                <Button color='success' onClick={handleModalAccept}>
                    Aceptar
                </Button>
                <Button color='danger' onClick={handleModalState}>
                    Cerrar
                </Button>
            </ModalFooter>
        </Modal>
    )
}
ModalOption.propTypes = {
    header: PropTypes.any,
    load: PropTypes.any,
    studentId: PropTypes.any,
    ciclo: PropTypes.any,
    empresa: PropTypes.any,
    sendData: PropTypes.any,
    boletin: PropTypes.bool,
    sent: PropTypes.any
};
export default ModalOption