import { useState } from 'react'
import { TabContent } from 'reactstrap'
import PropTypes from 'prop-types'
import DatosPersonales from "./DatosPersonales"
import Loader from '../../layouts/loader/Loader'
import DatosTutores from "./DatosTutores"
import Documentos from './Documentos'
import Sepa from './Sepa'
import DatosEscolares from './DatosEscolares'
import Contabilidad from './Contabilidad'
import Seguimiento from './Seguimiento'


const PestañasDatos = ({ setRecibosPendientes, prevActiveTab, activeTab, dataApi, missingDataFunction }) => {
    const [menorEdad, setMenorEdad] = useState(false);

    return (
        <>
            {
                dataApi === null
                    ? <Loader />
                    : dataApi.datos === null
                        ? <p className="mt-2 text-danger"><strong>Este alumno no dispone de datos.</strong></p>
                        : (
                            <TabContent style={{ padding: '24px 24px 0 24px' }} activeTab={ activeTab }>
                                <DatosPersonales prevActiveTab={prevActiveTab} datosPersonales={ dataApi.datos.personales } missingDataFunction={ missingDataFunction } menorEdad={ menorEdad } setMenorEdad={ setMenorEdad } />
                                <DatosEscolares prevActiveTab={prevActiveTab} datosEscolares={ dataApi.datos.escolares } datosPersonales={dataApi.datos.personales} missingDataFunction={ missingDataFunction } userId={ dataApi.id } />
                                <DatosTutores prevActiveTab={prevActiveTab} datosTutor={ dataApi.datos.tutores } missingDataFunction={ missingDataFunction } userId={ dataApi.id } menorEdad={ menorEdad } />
                                <Documentos datosDocumento={ dataApi.datos.personales.driveId } />
                                <Sepa prevActiveTab={prevActiveTab}/>
                                <Contabilidad setRecibosPendientes={ setRecibosPendientes } />
                                <Seguimiento />
                            </TabContent>
                        )
            }

        </>


    )
}

PestañasDatos.propTypes = {
    setRecibosPendientes: PropTypes.any,
    prevActiveTab: PropTypes.string,
    activeTab: PropTypes.string,
    dataApi: PropTypes.any,
    missingDataFunction: PropTypes.any
};

export default PestañasDatos