import { useEffect, useState, useContext } from 'react';
import {  useNavigate,useParams } from 'react-router-dom';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import ComponentCard from '../../layouts/containers/ComponentCard';
import ResumenSol from '../../components/matriculas/solicitudes/ResumenSol';
import { SearchMatContext } from '../../context/searchMatContext';

const Revision = (props) => {
  const { matriculaId: id, empresaId: headquarter } = useParams();
  const userSession = JSON.parse(sessionStorage.getItem('user'));

  const [idUsuario, setIdUsuario] = useState('');
  const [dataCharged, setDataCharged] = useState(false);
  const navigate = useNavigate()
  const {saveSearches, searchMatState    } = useContext(SearchMatContext)

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_NODE_API}matriculas/${id}?headquarter=${headquarter}&userEmail=${userSession.profileObj.email}`,
      {
        method: 'get',
        headers: {
          Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('user')).tokenId}`,
          'content-type': 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          Pragma: 'no-cache',
          Expires: '0',
        },
      },
    )
      .then((response) => response.json())
      .then((actualData) => {
        if (localStorage.getItem('enrollment')) localStorage.removeItem('enrollment');
        localStorage.setItem('enrollment', JSON.stringify(actualData));
        setDataCharged(true);
      })
      .catch((error) => {
        sessionStorage.setItem('apiError', error);
        sessionStorage.removeItem('user');
        window.location.reload();
      });
  }, []);

  if (!dataCharged) return null;

  
  const handleClick = () => {
      
      const search = searchMatState
 
      search.newSearch = false
       saveSearches(search)
       navigate('/solicitudes-matricula')
     };

  return (
    <>
      <Col md="12">
        <ComponentCard
          title={`Revisión de solicitud - ${headquarter} - ${id} - Creado por  - ${idUsuario}`}
          hasButton={false} handleClick={handleClick} dropdown
        >
          <ResumenSol tipo={props.tipo} idMatricula={id} setIdUsuario={setIdUsuario} handleOnClick={handleClick}/>
        </ComponentCard>
      </Col>
    </>
  );
};

Revision.propTypes = {
  tipo: PropTypes.number,
};

export default Revision;
