
const filtroSolicitudesMatricula = async ( userOU, userSession ) => {
        
    let url = '';
    let result = { badge: 0 };

    // Verificamos que rol estamos
    if(
        (userOU.idCedesca !== 0 && userOU.idCntec !== 0) &&
        (userOU.idCedesca !== -1 && userOU.idCntec !== -1) &&
        (userOU.idCedesca !== -2 && userOU.idCntec !== -2) &&
        (userOU.idCedesca !== -3 && userOU.idCntec !== -3)
    ) {
        url = `${ process.env.REACT_APP_NODE_API }getPeticionesPendientes?userEmail=${ userSession.profileObj.email }&headquarter=CEDETECGROUP&idCedesca=${ userOU.idCedesca }&idCntec=${ userOU.idCntec }&estado=5`;
    } else {
        url = `${ process.env.REACT_APP_NODE_API }getPeticionesPendientes?userEmail=${ userSession.profileObj.email }&headquarter=CEDETECGROUP&idCedesca=${ userOU.idCedesca }&idCntec=${ userOU.idCntec }&estado=6`;
    }

    // Hacemos la petición a CEDESCA
    try {

        const response = await fetch(url, {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${JSON.parse(sessionStorage.getItem('user')).tokenId}`,
                'content-type': 'application/json'
            }
        })
        
        result = await response.json();

    } catch( error ) {

        sessionStorage.setItem('apiError', error);
        sessionStorage.removeItem('user');
        window.location.reload();

    }

    return result;

}

export default filtroSolicitudesMatricula;