import { useEffect, useState, useContext } from "react";
import * as Icon from 'react-feather';
import { TabPane, Table, Pagination, PaginationItem, PaginationLink, Col, Row, Button} from "reactstrap";
import ModalSeguimiento from "../../layouts/modals/ModalSeguimiento";
import ModalOptions from "../../layouts/modals/ModalOptions";
import Loader from "../../layouts/loader/Loader";
import { AuthContext } from '../../context/authContext';

const Seguimiento = () => {
    const userSession = JSON.parse(sessionStorage.getItem('user'));
    const studentSession = JSON.parse(localStorage.getItem('student'));
    const opcionesFecha = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const { empresaId } = JSON.parse(localStorage.getItem('student'));
    const { userOU } = useContext( AuthContext );
    const [data, setData] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [modal, setModal] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [titulo, setTitulo] = useState('')
    const [comentario, setComentario] = useState('')
    const [raw, setRaw] = useState('')
    const [currentID, setCurrentID] = useState(0)
    const url = `${ process.env.REACT_APP_NODE_API }borrarSeguimiento?empresaId=${ empresaId }`

    // Toggle modal
    const toggleModal = (tituloP, comentarioP, id) => {
        setModal(!modal);
        setCurrentID(id)
        setTitulo(tituloP)
        setComentario(comentarioP)
    }

    const toggleModalDelete = (id) => {
        const rawInfo = {
            id,
            headquarter: empresaId,
            userEmail: userSession.profileObj.email,
          };
          setRaw(rawInfo)
        setModalDelete(!modalDelete);
        
    }

    // Fetch data from the API
    useEffect(() => {
        
        fetch(`${ process.env.REACT_APP_NODE_API }mostrarSeguimiento?userEmail=${ userSession.profileObj.email }&empresaId=${ studentSession.empresaId }&id=${ studentSession.id }`, {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${ userSession.tokenId }`,
                'content-type': 'application/json'
            }
        })
        .then((response) => response.json())
        .then((actualData) =>  {
            setData(actualData);
            setDataLoaded(true);
        })
        .catch(error => {
            sessionStorage.setItem('apiError', error);
            sessionStorage.removeItem('user');
            window.location.reload();
        });
    }, []);

    // Calculate the indexes for the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = data.slice(indexOfFirstItem, indexOfLastItem);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    

    // Create pagination items
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(data.length / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

      // Handle form submission from modal
      const handleSave = (newEntry) => {
        if (titulo === '' && comentario === ''){
            fetch(`${ process.env.REACT_APP_NODE_API }agregarSeguimiento`, {
                method: 'post',
                headers: {
                    'Authorization': `Bearer ${ userSession.tokenId }`,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ 
                    ...newEntry,
                    userEmail: userSession.profileObj.email,
                    empresaId: studentSession.empresaId,
                    ccodcli: studentSession.id
                })
            })
            .then(response => response.json())
            .then(newData => {
                setData([...data, newData]);
                toggleModal('','');
                window.location.href=`/alumnos/cedesca/${studentSession.id}/detalles`
            })
            .catch(error => {
                console.error("Error adding entry:", error);
            });
        }else{
            fetch(`${ process.env.REACT_APP_NODE_API }editarSeguimiento`, {
                method: 'post',
                headers: {
                    'Authorization': `Bearer ${ userSession.tokenId }`,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ 
                    ...newEntry,
                    userEmail: userSession.profileObj.email,
                    empresaId: studentSession.empresaId,
                    ccodcli: studentSession.id,
                    idSeguimiento: currentID
                })
            })
            .then(response => response.json())
            .then(newData => {
                setData([...data, newData]);
                toggleModal('','');
                window.location.href=`/alumnos/cedesca/${studentSession.id}/detalles`
            })
            .catch(error => {
                console.error("Error adding entry:", error);
            });
        }
        
    };

    return (
        <TabPane tabId="7">
             {modal && 
                        <ModalSeguimiento
                        isOpen={modal} 
                        toggle={toggleModal} 
                        onSave={handleSave} 
                        setIsOpen={setModal}
                        titulo={titulo}
                        comentario={comentario}
                    />}
                    {modalDelete && 
                        <ModalOptions header="¿Desea borrar?" url={ url } raw={raw} load={ setModalDelete } text1='Cancelar' color1='danger' text2='Aceptar' color2='primary'/>}
            {
                data.length === 0 && !dataLoaded ? (
                    <Loader />
                ) : data.length === 0 ? (
                    <>
                    <p>No se han encontrado registros.</p>
                    <Row>
                            <Col sm="9"><Button color="success" onClick={() => toggleModal('','')}>Añadir</Button></Col>
                    </Row>
                    </>                    
                ) : (
                    <>
                        <Table responsive borderless style={{ fontSize:"13px", borderCollapse: "separate", borderSpacing:"0 1em" }}>
                            <thead>
                                <tr>
                                <th style={{ width: "100px" }}>ID</th>
            <th style={{ width: "150px" }}>Fecha</th>
            <th style={{ width: "200px" }}>Usuario</th>
            <th style={{ width: "300px" }}>Título</th>
            <th style={{ width: "400px" }}>Comentarios</th>
            <th style={{ width: "150px" }}>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                                {
                                    
                                    currentData.map((r) => (
                                        
                                        <tr key={r.numFactura}>
                                            <td className={r.estado !== 'Pendiente' ? 'text-black-75': ''}>{ r.id }</td>
                                            <td className={r.estado !== 'Pendiente' ? 'text-black-75': ''}>{ new Intl.DateTimeFormat('es-ES', opcionesFecha).format(new Date(r.fecha)) }</td>
                                            <td className={r.estado !== 'Pendiente' ? 'text-black-75': ''}>{ r.usuario }</td>
                                            <td className={r.estado !== 'Pendiente' ? 'text-black-75': ''}>{ r.titulo }</td>
                                            <td className={r.estado !== 'Pendiente' ? 'text-black-75': ''}>{ r.comentarios }</td>
                                            <td className={r.estado !== 'Pendiente' ? 'text-black-75': ''}>
                                            <Button type="submit" color="warning" disabled={userOU.centro === 'ORIENTACIÓN'} className="me-1"  onClick={() => toggleModal(r.titulo, r.comentarios, r.id)}>
                                    <Icon.Edit />
                                </Button>
                                <Button type="submit" color="danger" disabled={userOU.centro === 'ORIENTACIÓN'}  onClick={() => toggleModalDelete(r.id)}>
                                    <Icon.Trash />
                                </Button>
                                            </td>
                                        </tr>    
                                    ))
                                }
                            </tbody>
                        </Table>
                        <Row>
                            <Col sm="3"><Button color="success" onClick={() => toggleModal('','')}>Añadir</Button></Col>
                            <Col sm="9" className="d-flex justify-content-end">
                            <Pagination size="sm">
                            {pageNumbers.map(number => (
                                <PaginationItem key={number} active={number === currentPage}>
                                    <PaginationLink onClick={() => paginate(number)}>
                                        {number}
                                    </PaginationLink>
                                </PaginationItem>
                            ))}
                        </Pagination>
                        </Col>
                        </Row>
                    </>
                )
            }
        </TabPane>
    );
}

export default Seguimiento;
