import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Form, Input, Row, Button, Spinner } from 'reactstrap';
import Select from 'react-select';
import Loader from '../../../layouts/loader/Loader';
import ListadoSolicitudes from './ListadoSolicitudes';
import ModalLoader from '../../../layouts/modals/ModalLoader';
import { AuthContext } from '../../../context/authContext';
import { SearchMatContext } from '../../../context/searchMatContext';

const FiltroSolicitudes = () => {
  const navigate = useNavigate();
  const [dataApi, setDataApi] = useState('');
  const userSession = JSON.parse(sessionStorage.getItem('user'));
  const [dni, setDni] = useState({ data: '', error: '' });
  const [email, setEmail] = useState({ data: '', error: '' });
  const [name, setName] = useState({ data: '', error: '' });
  const [lastname, setLastname] = useState({ data: '', error: '' });
  const [idRequest, setIdRequest] = useState({ data: '', error: '' });
  const [state, setState] = useState('');
  const [center, setCenter] = useState('');
  const [headquarterList, setHeadquarterList] = useState('');
  const [bd, setBd] = useState('CEDETECGROUP');
  const [course, setCourse] = useState('');
  const [coursesList, setCoursesList] = useState('');
  const [centersList, setCentersList] = useState('');
  const [statesList, setStatesList] = useState('');
  const [isFormSubmittable, setIsFormSubmittable] = useState(true);
  const [isTableNotLoading, setIsTableNotLoading] = useState(true);
  const [isProceedingLoading, setIsProceedingLoading] = useState(false);
  const [deudores, setDeudores] = useState(false);
  const { userOU } = useContext(AuthContext);
  const {searchMatState, saveSearches} = useContext(SearchMatContext)

  const CustomClearText = () => 'borrar todo';
  const handleDeudor = () => {
    setDeudores(!deudores);
  };
  const ClearIndicator = (props) => {
    const {
      // eslint-disable-next-line react/prop-types
      children = <CustomClearText />,
      // eslint-disable-next-line react/prop-types
      getStyles,
      // eslint-disable-next-line react/prop-types
      innerProps: { ref, ...restInnerProps },
    } = props;
    return (
      <div {...restInnerProps} ref={ref} style={getStyles('clearIndicator', props)}>
        <div style={{ padding: '0px 5px' }}>{children}</div>
      </div>
    );
  };
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_NODE_API}consultaEmpresas?userEmail=${userSession.profileObj.email}&headquarter=1`,
      {
        method: 'get',
        redirect: 'follow',
        headers: {
          Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('user')).tokenId}`,
          'content-type': 'application/json',
        },
      },
    )
      .then((response) => response.json())
      .then((actualData) => {
        setHeadquarterList(actualData);
      });

    fetch(
      `${process.env.REACT_APP_NODE_API}buscarCiclos?headquarter=CEDESCA&userEmail=${userSession.profileObj.email}`,
      {
        method: 'get',
        headers: {
          Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('user')).tokenId}`,
          'content-type': 'application/json',
        },
      },
    )
      .then((response) => response.json())
      .then((actualData) => {
        setCoursesList(actualData);
      })
      .catch((error) => {
        sessionStorage.setItem('apiError', error);
        sessionStorage.removeItem('user');
        window.location.href = '/';
      });

    fetch(
      `${process.env.REACT_APP_NODE_API}buscarCentros?headquarter=CEDESCA&userEmail=${userSession.profileObj.email}`,
      {
        method: 'get',
        headers: {
          Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('user')).tokenId}`,
          'content-type': 'application/json',
        },
      },
    )
      .then((response) => response.json())
      .then((actualData) => {
        const opcionesRenombradas = actualData.map((opcion) => ({
          value: opcion.id,
          label: opcion.descripcion,
        }));

        setCentersList(opcionesRenombradas);
      })
      .catch((error) => {
        sessionStorage.setItem('apiError', error);
        sessionStorage.removeItem('user');
        window.location.href = '/';
      });

    fetch(
      `${process.env.REACT_APP_NODE_API}buscarEstados?headquarter=CEDESCA&userEmail=${userSession.profileObj.email}`,
      {
        method: 'get',
        headers: {
          Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('user')).tokenId}`,
          'content-type': 'application/json',
        },
      },
    )
      .then((response) => response.json())
      .then((actualData) => {
        setStatesList(actualData);
      })
      .catch((error) => {
        sessionStorage.setItem('apiError', error);
        sessionStorage.removeItem('user');
        window.location.href = '/';
      });
  }, []);

 


  const handleRequest = (id, headquarter) => {
    const empresaLowerCase = headquarter.toLowerCase();
    if (localStorage.getItem('enrollment')) localStorage.removeItem('enrollment');
    navigate(`${empresaLowerCase}/${id}/revision`);
  };

  const handleReset = () => {
    setName({ data: '', error: '' });
    setLastname({ data: '', error: '' });
    setDni({ data: '', error: '' });
    setEmail({ data: '', error: '' });
    setBd('CEDETECGROUP');
    setCourse('');
    setState('');
    setCenter('');
    setIdRequest({ data: '', error: '' });
  };

  const downloadExcel = () => {
    setIsProceedingLoading(true);
    let delegaciones = '';

    if (center !== '' && userOU.idCedesca > 0) {
      center.forEach((opcion) => {
        if (delegaciones === '') {
          delegaciones += `'${opcion.label}'`;
        } else {
          delegaciones += `,'${opcion.label}'`;
        }
      });
    } else {
      delegaciones += `'${userOU.centro}'`;
    }

    let url = `${process.env.REACT_APP_NODE_API}filtrarSolicitudes?&userEmail=${userSession.profileObj.email}&headquarter=${bd}&nombre=${name.data}&apellidos=${lastname.data}&dni=${dni.data}&email=${email.data}&descarga=true&nombreDelegacion=${delegaciones}&deudor=${deudores}`;
    if (center !== '') {
      url += `&sede=${center}`;
    }
    if (state !== '') {
      url += `&estado=${state}`;
    }
    if (course !== '') {
      url += `&idPlan=${course}`;
    }
    if (idRequest !== '') {
      url += `&idRequest=${idRequest.data}`;
    }
    fetch(url, {
      method: 'get',
      redirect: 'follow',
      mode: 'cors',
      cache: 'no-store',
      referrerPolicy: 'no-referrer',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('user')).tokenId}`,
        'content-type': 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        Expires: '0',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url2 = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url2;
        a.download = 'document.xlsx';
        a.click();
        window.URL.revokeObjectURL(url2);
        setIsProceedingLoading(false);
      });
  };

  const handleOnSubmit = (e) => {
    if (e !== undefined){
      e.preventDefault();

    }

   
    setIsTableNotLoading(false);
    let delegaciones = '';
    if (center !== '') {
      center.forEach((opcion) => {
        if (delegaciones === '') {
          delegaciones += `'${opcion.label}'`;
        } else {
          delegaciones += `,'${opcion.label}'`;
        }
      });
    }

    let url = '';

    if (
      (userOU.idCedesca === 0 && userOU.idCntec === 0) ||
      (userOU.idCedesca === -1 && userOU.idCntec === -1) || (userOU.idCedesca === -2 && userOU.idCntec === -2) || (userOU.idCedesca === -3 && userOU.idCntec === -3)
    ) {
      // Petición si es Admin/IT o Sede
      url = `${process.env.REACT_APP_NODE_API}filtrarSolicitudes?&userEmail=${userSession.profileObj.email}&headquarter=${bd}&nombre=${name.data}&apellidos=${lastname.data}&dni=${dni.data}&email=${email.data}&id=${idRequest.data}&nombreDelegacion=${delegaciones}&deudor=${deudores}`;
      
      if (center !== '') {
        url += `&sede=${center}`;
      }
      if (state !== '') {
        url += `&estado=${state}`;
      }
      if (course !== '') {
        url += `&idPlan=${course}`;
      }
      if (idRequest !== '') {
        url += `&idRequest=${idRequest.data}`;
      }
   
      fetch(url, {
        method: 'get',
        headers: {
          Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('user')).tokenId}`,
          'content-type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((actualData) => {
          setDataApi(actualData);
          actualData.forEach((element) => {
            element.actions = (
              <>
                <Button
                  color="success"
                  onClick={() => {
                    handleRequest(element.num_matricula, element.headquarter);
                  }}
                >
                  <a className="text-light text-decoration-none">Detalles</a>
                </Button>
              </>
            );
          });
          setIsTableNotLoading(true);
        })
        .catch((error) => {
          sessionStorage.setItem('apiError', error);
          sessionStorage.removeItem('user');
          window.location.href = '/';
        });
    } else if (bd === 'CEDESCA') {
      url = `${process.env.REACT_APP_NODE_API}filtrarSolicitudes?&userEmail=${userSession.profileObj.email}&headquarter=CEDESCA&nombre=${name.data}&apellidos=${lastname.data}&dni=${dni.data}&email=${email.data}&id=${idRequest.data}&idDelegacion=${userOU.idCedesca}&deudor=${deudores}`;

      if (center !== '') {
        url += `&sede=${center}`;
      }
      if (state !== '') {
        url += `&estado=${state}`;
      }
      if (course !== '') {
        url += `&idPlan=${course}`;
      }
      if (idRequest !== '') {
        url += `&idRequest=${idRequest.data}`;
      }
      fetch(url, {
        method: 'get',
        headers: {
          Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('user')).tokenId}`,
          'content-type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((actualData) => {
          setDataApi(actualData);
          actualData.forEach((element) => {
            element.actions = (
              <>
                <Button
                  color="success"
                  onClick={() => {
                    handleRequest(element.num_matricula, element.headquarter);
                  }}
                >
                  <a className="text-light text-decoration-none">Detalles</a>
                </Button>
              </>
            );
          });
          setIsTableNotLoading(true);
        })
        .catch((error) => {
          sessionStorage.setItem('apiError', error);
          sessionStorage.removeItem('user');
          window.location.reload();
        });
    } else if (bd === 'CNTEC') {
      url = `${process.env.REACT_APP_NODE_API}filtrarSolicitudes?&userEmail=${userSession.profileObj.email}&headquarter=CNTEC&nombre=${name.data}&apellidos=${lastname.data}&dni=${dni.data}&email=${email.data}&id=${idRequest.data}&idDelegacion=${userOU.idCntec}&deudor=${deudores}`;

      if (center !== '') {
        url += `&sede=${center}`;
      }
      if (state !== '') {
        url += `&estado=${state}`;
      }
      if (course !== '') {
        url += `&idPlan=${course}`;
      }
      if (idRequest !== '') {
        url += `&idRequest=${idRequest.data}`;
      }

      fetch(url, {
        method: 'get',
        headers: {
          Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('user')).tokenId}`,
          'content-type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((actualData) => {
          setDataApi(actualData);
          actualData.forEach((element) => {
            element.actions = (
              <>
                <Button
                  color="success"
                  onClick={() => {
                    handleRequest(element.num_matricula, element.headquarter);
                  }}
                >
                  <a className="text-light text-decoration-none">Detalles</a>
                </Button>
              </>
            );
          });
          setIsTableNotLoading(true);
        })
        .catch((error) => {
          sessionStorage.setItem('apiError', error);
          sessionStorage.removeItem('user');
          window.location.href = '/';
        });
    } else {
      url = `${process.env.REACT_APP_NODE_API}filtrarSolicitudes?&userEmail=${userSession.profileObj.email}&headquarter=CEDESCA&nombre=${name.data}&apellidos=${lastname.data}&dni=${dni.data}&email=${email.data}&id=${idRequest.data}&idDelegacion=${userOU.idCedesca}&deudor=${deudores}`;
      
      if (center !== '') {
        url += `&sede=${center}`;
      }
      if (state !== '') {
        url += `&estado=${state}`;
      }
      if (course !== '') {
        url += `&idPlan=${course}`;
      }
      if (idRequest !== '') {
        url += `&idRequest=${idRequest.data}`;
      }
      fetch(url, {
        method: 'get',
        headers: {
          Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('user')).tokenId}`,
          'content-type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((actualDataCedesca) => {
          setDataApi(actualDataCedesca);
          actualDataCedesca.forEach((element) => {
            element.actions = (
              <>
                <Button
                  color="success"
                  onClick={() => {
                    handleRequest(element.num_matricula, element.headquarter);
                  }}
                >
                  <a className="text-light text-decoration-none">Detalles</a>
                </Button>
              </>
            );
          });

          url = `${process.env.REACT_APP_NODE_API}filtrarSolicitudes?&userEmail=${userSession.profileObj.email}&headquarter=CNTEC&nombre=${name.data}&apellidos=${lastname.data}&dni=${dni.data}&email=${email.data}&id=${idRequest.data}&idDelegacion=${userOU.idCntec}&deudor=${deudores}`;

          if (center !== '') {
            url += `&sede=${center}`;
          }
          if (state !== '') {
            url += `&estado=${state}`;
          }
          if (course !== '') {
            url += `&idPlan=${course}`;
          }
          if (idRequest !== '') {
            url += `&idRequest=${idRequest.data}`;
          }

          fetch(url, {
            method: 'get',
            headers: {
              Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('user')).tokenId}`,
              'content-type': 'application/json',
            },
          })
            .then((response) => response.json())
            .then((actualDataCntec) => {
              setDataApi((prev) => [...prev, ...actualDataCntec]);

              actualDataCntec.forEach((element) => {
                element.actions = (
                  <>
                    <Button
                      color="success"
                      onClick={() => {
                        handleRequest(element.num_matricula, element.headquarter);
                      }}
                    >
                      <a className="text-light text-decoration-none">Detalles</a>
                    </Button>
                  </>
                );
              });
              setIsTableNotLoading(true);
            })
            .catch((error) => {
              sessionStorage.setItem('apiError', error);
              sessionStorage.removeItem('user');
              window.location.reload();
            });
        })
        .catch((error) => {
          sessionStorage.setItem('apiError', error);
          sessionStorage.removeItem('user');
          window.location.href = '/';
        });
    }
    
    const search = {
      nameSearch: name.data,
      lastnameSearch: lastname.data,
      stateSearch: state,
      centerSearch: center,
      courseCenter: course,
      idMatSearch: idRequest.data,
      emailSearch: email.data,
      dniSearch: dni.data,
      deudores,
      dbSearch: 'CEDETECGROUP',
      newSearch: true
    }

   


    saveSearches(search)
  };
  useEffect(() =>{
    console.log(searchMatState, 'yeee')
    if (
      searchMatState !== '' &&
       searchMatState.newSearch === false
    ) {
    
      setName({ data: searchMatState.nameSearch, error: '' })
      setLastname({ data: searchMatState.lastnameSearch, error: '' })
      setState(searchMatState.stateSearch)
      setCenter(searchMatState.centerSearch)
      setCourse(searchMatState.courseCenter)
      setIdRequest({ data: searchMatState.idMatSearch, error: '' })
      setEmail({ data: searchMatState.emailSearch, error: '' })
      setDni({ data: searchMatState.dniSearch, error: '' })
      setBd( searchMatState.dbSearch)
      setDeudores(searchMatState.deudores)
      setIsTableNotLoading(false)

      let delegaciones = '';
    if (searchMatState.centerSearch !== '') {
      searchMatState.centerSearch.forEach((opcion) => {
        if (delegaciones === '') {
          delegaciones += `'${opcion.label}'`;
        } else {
          delegaciones += `,'${opcion.label}'`;
        }
      });
    }
    
    let url = `${process.env.REACT_APP_NODE_API}filtrarSolicitudes?userEmail=${userSession.profileObj.email}&headquarter=${searchMatState.dbSearch}&nombre=${searchMatState.nameSearch}&apellidos=${searchMatState.lastnameSearch}&dni=${searchMatState.dniSearch}&email=${searchMatState.emailSearch}&id=${searchMatState.idMatSearch}&idDelegacion=${userOU.idCntec}&deudor=${searchMatState.deudores}`;
   
      if (searchMatState.centerSearch !== '') {
        url += `&sede=${searchMatState.centerSearch}`;
      }
      if (searchMatState.stateSearch !== '') {
        url += `&estado=${searchMatState.stateSearch}`;
      }
      if (idRequest !== '') {
        url += `&idRequest=${searchMatState.idMatSearch}`;
      }

      if(course !== ''){
        url += `&idPlan=${searchMatState.courseCenter}`;
      }

      if(parseInt(userOU.idCedesca, 10) > 0 ){
        url += `&nombreDelegacion=${userOU.centro}`;
      }

      fetch(url, {
        method: 'get',
        headers: {
          Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('user')).tokenId}`,
          'content-type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((actualData) => {
          setDataApi(actualData);
          actualData.forEach((element) => {
            element.actions = (
              <>
                <Button
                  color="success"
                  onClick={() => {
                    handleRequest(element.num_matricula, element.headquarter);
                  }}
                >
                  <a className="text-light text-decoration-none">Detalles</a>
                </Button>
              </>
            );
          });
          setIsTableNotLoading(true);
        })
        .catch((error) => {
          sessionStorage.setItem('apiError', error);
          sessionStorage.removeItem('user');
          window.location.href = '/';
        });
    }
    
  }, [searchMatState.newSearch])
  const handleNameOnChange = (e) => {
    if (e.target.value.trim().length === 0) {
      setName({ data: '', error: '' });
      setIsFormSubmittable(true);
    } else if (!e.target.value.trim().match(/^(?![\s.]+$)[a-zA-ZÀ-ÿ\s.]*$/)) {
      setName({ data: '', error: 'format' });
      setIsFormSubmittable(false);
    } else if (e.target.value.trim().length === 1) {
      setName({ data: '', error: 'length' });
      setIsFormSubmittable(false);
    } else {
      setName({ data: e.target.value.trim(), error: '' });
      setIsFormSubmittable(true);
    }
  };
  const handleLastNameOnChange = (e) => {
    if (!e.target.value.trim().match(/^(?![\s.]+$)[a-zA-ZÀ-ÿ\s.]*$/)) {
      setLastname({ data: '', error: 'format' });
      setIsFormSubmittable(false);
    } else if (e.target.value.trim().length === 1) {
      setLastname({ data: '', error: 'length' });
      setIsFormSubmittable(false);
    } else {
      setLastname({ data: e.target.value.trim(), error: '' });
      setIsFormSubmittable(true);
    }
  };
  const handleDNIOnChange = (e) => {
    setDni({ data: e.target.value.trim(), error: '' });
    setIsFormSubmittable(true);
  };

  const handleIDOnChange = (e) => {
    setIdRequest({ data: e.target.value.trim(), error: '' });
    setIsFormSubmittable(true);
  };
  const handleEmailOnChange = (e) => {
    if (
      e.target.value.trim().length > 0 &&
      !e.target.value
        .trim()
        .toLowerCase()
        .match(
          /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
        )
    ) {
      setEmail({ data: '', error: 'format' });
      setIsFormSubmittable(false);
    } else {
      setEmail({ data: e.target.value.trim(), error: '' });
      setIsFormSubmittable(true);
    }
  };

  const handleCourses = (e) => {
    if (e.target.value === 'all') {
      setCourse('');
    } else {
      setCourse(e.target.value);
    }
  };
  const handleState = (e) => {
    if (e.target.value === 1) {
      setState('');
    } else {
      setState(e.target.value);
    }
  };
  const handleCenters = (selected) => {
    setCenter(selected);
  };
  const handleHeadquarter = (e) => {
    setBd(e.target.value);
  };
  return (
    <>
      {isProceedingLoading ? <ModalLoader header="Generando documento" /> : ''}
      {headquarterList === '' || coursesList === '' || centersList === '' || statesList === '' ? (
        <Loader />
      ) : (
        <Form onSubmit={handleOnSubmit}>
          <Row className="mb-3">
            <Col sm="3">
              <label htmlFor="centros" className="form-label">
                Centro
              </label>
              <select
                className="form-select"
                name="centros"
                id="centros"
                onChange={handleHeadquarter}
                disabled={isTableNotLoading ? '' : 'disabled'}
                value={bd}
              >
                {headquarterList !== ''
                  ? headquarterList.map((option) => (
                      <option key={option.codigo} value={option.codigo}>
                        {option.nombre}
                      </option>
                    ))
                  : ''}
              </select>
            </Col>
            <Col sm="4">
              <label htmlFor="cursos" className="form-label">
                Curso
              </label>
              <select
                className="form-select"
                name="cursos"
                id="cursos"
                onChange={handleCourses}
                disabled={isTableNotLoading ? '' : 'disabled'}
                value={course}
              >
                {coursesList !== ''
                  ? coursesList.map((option) => (
                      <option key={option.IdPlan} value={option.IdPlan}>
                        {option.ciclo}
                      </option>
                    ))
                  : ''}
              </select>
            </Col>
            <Col sm="2">
              <label htmlFor="estado" className="form-label">
                Estado
              </label>
              <select
                className="form-select"
                name="estado"
                id="estado"
                onChange={handleState}
                disabled={isTableNotLoading ? '' : 'disabled'}
                value={state}
              >
                {statesList !== ''
                  ? statesList.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.descripcion}
                      </option>
                    ))
                  : ''}
              </select>
            </Col>
            <Col sm="3">
              <label htmlFor="centroestudio" className="form-label">
                Centro de estudios
              </label>
              {userOU.idCedesca > 0 ? (
                <Input type="text" name="centroFormacion" placeholder={userOU.centro} disabled />
              ) : (
                <Select
                  options={centersList}
                  closeMenuOnSelect={false}
                  value={center}
                  isMulti
                  onChange={handleCenters}
                  disabled={isTableNotLoading ? '' : 'disabled'}
                  components={{ ClearIndicator }}
                  placeholder="Seleccionar..."
                />
              )}
            </Col>
          </Row>
          <Row className="mt-auto mt-md-2" style={{ alignItems: 'flex-end' }}>
            <Col sm="2" className="mt-1 mt-md-0" onChange={handleNameOnChange}>
              {searchMatState !== null &&
              searchMatState.newSearch === false ? (
                <>
                  <label htmlFor="name" className="form-label">
                    Nombre
                  </label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Buscar por nombre"
                    onChange={handleNameOnChange}
                    onInput={handleNameOnChange}
                    className={name.error !== '' ? 'border border-danger error' : ''}
                    defaultValue={name.data}
                    disabled={!isTableNotLoading}
                  />
                </>
              ) : (
                <>
                  <label htmlFor="name" className="form-label">
                    Nombre
                  </label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Buscar por nombre"
                    onChange={handleNameOnChange}
                    onInput={handleNameOnChange}
                    className={name.error !== '' ? 'border border-danger error' : ''}
                    disabled={!isTableNotLoading}
                  />
                </>
              )}
              {name.error === 'length' && (
                <span className="text-danger tiny">Debe tener mínimo 2 caracteres</span>
              )}
              {name.error === 'format' && (
                <span className="text-danger tiny">Números o símbolos no permitidos</span>
              )}
            </Col>
            <Col sm="3" className="mt-1 mt-md-0">
              {searchMatState !== null &&
              searchMatState.newSearch === false ? (
                <>
                  <label htmlFor="lastname" className="form-label">
                    Apellidos
                  </label>
                  <Input
                    type="text"
                    name="lastname"
                    id="lastname"
                    placeholder="Buscar por apellidos"
                    onChange={handleLastNameOnChange}
                    onInput={handleLastNameOnChange}
                    className={lastname.error !== '' ? 'border border-danger error' : ''}
                    defaultValue={lastname.data}
                    disabled={!isTableNotLoading}
                  />
                </>
              ) : (
                <>
                  <label htmlFor="lastname" className="form-label">
                    Apellidos
                  </label>
                  <Input
                    type="text"
                    name="lastname"
                    id="lastname"
                    placeholder="Buscar por apellidos"
                    onChange={handleLastNameOnChange}
                    onInput={handleLastNameOnChange}
                    className={lastname.error !== '' ? 'border border-danger error' : ''}
                    disabled={!isTableNotLoading}
                  />
                </>
              )}
              {lastname.error === 'length' && (
                <span className="text-danger tiny">Debe tener mínimo 2 caracteres</span>
              )}
              {lastname.error === 'format' && (
                <span className="text-danger tiny">Números o símbolos no permitidos</span>
              )}
            </Col>

            <Col sm="2" className="mt-1 mt-md-0">
              {searchMatState !== null &&
              searchMatState.newSearch === false ? (
                <>
                  <label htmlFor="dni" className="form-label">
                    DNI
                  </label>
                  <Input
                    type="text"
                    name="dni"
                    id="dni"
                    placeholder="Buscar por DNI"
                    onChange={handleDNIOnChange}
                    onInput={handleDNIOnChange}
                    className={dni.error !== '' ? 'border border-danger error' : ''}
                    defaultValue={dni.data}
                    disabled={!isTableNotLoading}
                  />
                </>
              ) : (
                <>
                  <label htmlFor="dni" className="form-label">
                    DNI
                  </label>
                  <Input
                    type="text"
                    name="dni"
                    id="dni"
                    placeholder="Buscar por DNI"
                    onChange={handleDNIOnChange}
                    onInput={handleDNIOnChange}
                    className={dni.error !== '' ? 'border border-danger error' : ''}
                    disabled={!isTableNotLoading}
                  />
                </>
              )}
              {dni.error === 'format' && (
                <span className="text-danger tiny">Formato incorrecto</span>
              )}
            </Col>
            <Col sm="3">
              {searchMatState !== null &&
              searchMatState.newSearch === false ? (
                <>
                  <label htmlFor="email" className="form-label">
                    Correo electrónico
                  </label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Buscar por Email"
                    onChange={handleEmailOnChange}
                    onInput={handleEmailOnChange}
                    className={email.error !== '' ? 'border border-danger error' : ''}
                    defaultValue={email.data}
                    disabled={!isTableNotLoading}
                  />
                </>
              ) : (
                <>
                  <label htmlFor="email" className="form-label">
                    Correo electrónico
                  </label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Buscar por Email"
                    onChange={handleEmailOnChange}
                    onInput={handleEmailOnChange}
                    className={email.error !== '' ? 'border border-danger error' : ''}
                    disabled={!isTableNotLoading}
                  />
                </>
              )}
              {email.error === 'format' && (
                <span className="text-danger tiny">Formato incorrecto</span>
              )}
            </Col>
            <Col sm="2">
              {searchMatState !== null &&
              searchMatState.newSearch === false ? (
                <>
                  <label htmlFor="idRequest" className="form-label">
                    ID
                  </label>
                  <Input
                    type="text"
                    name="text"
                    id="idRequest"
                    placeholder="ID"
                    onChange={handleIDOnChange}
                    onInput={handleIDOnChange}
                    className={idRequest.error !== '' ? 'border border-danger error' : ''}
                    defaultValue={idRequest.data}
                    disabled={!isTableNotLoading}
                  />
                </>
              ) : (
                <>
                  <label htmlFor="email" className="form-label">
                    ID
                  </label>
                  <Input
                    type="text"
                    name="text"
                    id="idRequest"
                    placeholder="ID"
                    onChange={handleIDOnChange}
                    onInput={handleIDOnChange}
                    className={idRequest.error !== '' ? 'border border-danger error' : ''}
                    disabled={!isTableNotLoading}
                  />
                </>
              )}
              {idRequest.error === 'format' && (
                <span className="text-danger tiny">Formato incorrecto</span>
              )}
            </Col>
          </Row>
          <Row className="mt-md-3">
            <Col sm="3">
              <label htmlFor="es" className="form-label">
                Deudores
              </label>
              <Input
                type="checkbox"
                id="deudores"
                name="deudores"
                className="ms-4"
                onChange={handleDeudor}
              />
            </Col>
          </Row>
          <Row className="mt-md-3">
            <Col sm>
              <Button
                className="float-end"
                color="success"
                type="button"
                onClick={downloadExcel}
                disabled={dataApi === '' || !isTableNotLoading}
              >
                <i className="bi-file-earmark-spreadsheet" />
              </Button>
              <Button
                type="reset"
                color="warning"
                onClick={handleReset}
                className={!isTableNotLoading ? 'disabled float-end me-3' : 'float-end me-3'}
              >
                Reset
              </Button>
              <Button
                type="submit"
                className={
                  !isFormSubmittable || !isTableNotLoading
                    ? 'mt-0 me-3 disabled float-end'
                    : 'mt-0 me-3 float-end'
                }
              >
                {!isTableNotLoading ? (
                  <>
                    Cargando <Spinner color="primary" style={{ height: '15px', width: '15px' }} />{' '}
                  </>
                ) : (
                  'Buscar'
                )}
              </Button>
            </Col>
          </Row>
        </Form>
      )}

      {dataApi === '' ? '' : <ListadoSolicitudes dataApi={dataApi} />}
    </>
  );
};

export default FiltroSolicitudes;
