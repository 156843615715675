import { useEffect, useState } from 'react';

const Histórico = () => {
  const [dataLogs, setDataLogs] = useState('');
  const localEnrollment = JSON.parse(localStorage.getItem('enrollment'));
  const userSession = JSON.parse(sessionStorage.getItem('user'));

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_NODE_API}mostrarCambios?headquarter=${localEnrollment.headquarter}&id=${localEnrollment.id}&userEmail=${userSession.profileObj.email}`,
      {
        method: 'get',
        headers: {
          Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('user')).tokenId}`,
          'content-type': 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        },
      },
    )
      .then((response) => response.json())
      .then((actualData) => {
        setDataLogs(actualData);
      })
  }, [])

  if (dataLogs.length === 0) {
    return null; // Devuelve null si no hay datos para mostrar
  }

  return (
    <>
      <h4>Histórico</h4>
      <div
        style={{
          height: '300px',
          overflowY: 'auto',
          marginBottom: '30px',
          backgroundColor: '#DCDCDC',
        }}
      >
        {
          dataLogs.map((log) => (
            <div key={log.id}>
              {
                (() => {
                  switch (log.ACTION) {
                    case 'actualizarModulos':
                      return (
                        log.description !== '""' ? (
                          <p
                            key={log.id}
                            className="rounded p-3 mt-3"
                            style={{
                              marginRight: '30px',
                              marginLeft: '30px',
                              backgroundColor: '#D8B05C',
                              color: 'white',
                            }}
                          >
                            {log.formattedDate} - El usuario {log.nombre} ha hecho los siguientes cambios: {log.description.replace(/["[\]]/g, '')}
                          </p>
                        ) : null
                      );
                    case 'agregarAsignatura':
                      return (
                        <p
                          key={log.id}
                          className="rounded p-3 mt-3 "
                          style={{
                            marginRight: '30px',
                            marginLeft: '30px',
                            backgroundColor: '#75A654',
                            color: 'white',
                          }}
                        >
                          {log.formattedDate} - El usuario {log.nombre} ha agregado :{' '}{log.description.replace(/["[\]]/g, '')}
                        </p>
                      );
                    case 'obs':
                      return (
                        <p
                          key={log.id}
                          className="rounded p-3 mt-3 "
                          style={{
                            marginRight: '30px',
                            marginLeft: '30px',
                            backgroundColor: '#75A654',
                            color: 'white',
                          }}
                        >
                          {log.formattedDate} - El usuario {log.nombre} ha hecho la siguiente observación: {log.description}{' '}
                        </p>
                      );
                    case 'Comentario':
                      return (
                        <p
                          key={log.id}
                          className="rounded p-3 mt-3 "
                          style={{
                            marginRight: '30px',
                            marginLeft: '30px',
                            backgroundColor: '#75A654',
                            color: 'white',
                          }}
                        >
                          {log.formattedDate} - El usuario {log.nombre} ha hecho el siguiente comentario: {log.description}{' '}
                        </p>
                      );
                    case 'cambiarEstado':
                      return (
                        <p
                          key={log.id}
                          className="rounded p-3 mt-3 "
                          style={{
                            marginRight: '30px',
                            marginLeft: '30px',
                            backgroundColor: '#69BDDF',
                            color: 'white',
                          }}
                        >
                          {log.formattedDate} - El usuario {log.nombre} ha cambiado el estado a: {log.description}{' '}
                        </p>
                      );
                    case 'crearConvocatoria':
                      const logObject = JSON.parse(log.description)

                      // Objeto de mapeo para idEstado
                      const estadoMap = {
                        1: "No Presentado",
                        2: "Aprobado",
                        3: "Suspendido",
                        4: "Convalidado D",
                        5: "Exento",
                        6: "Convalidado C",
                        10: "Recalificado"
                      };

                      // Obtén el string descriptivo del estado
                      const estadoDescripcion = estadoMap[logObject.idEstado] || "Estado desconocido";

                      return (
                        <p
                          key={log.id}
                          className="rounded p-3 mt-3 "
                          style={{
                            marginRight: '30px',
                            marginLeft: '30px',
                            backgroundColor: '#69BDDF',
                            color: 'white',
                          }}
                        >
                          {log.formattedDate} - El usuario {log.nombre} ha creado la convocatoria con la siguiente descripción:  {logObject.description}{' '}. Nota: {logObject.nota} y estado: {estadoDescripcion}.
                        </p>
                      );
                    case 'eliminarConvocatoria':
                      const logObjectE = JSON.parse(log.description)
                      // Objeto de mapeo para idEstado

                      return (
                        <p
                          key={log.id}
                          className="rounded p-3 mt-3 "
                          style={{
                            marginRight: '30px',
                            marginLeft: '30px',
                            backgroundColor: '#A65454',
                            color: 'white',
                          }}
                        >
                          {log.formattedDate} - El usuario {log.nombre} ha borrado la convocatoria con id {logObjectE.idConvocatoria} del módulo {logObjectE.nombre} y UF/C {logObjectE.descripcion}.
                        </p>
                      );
                    case 'guardarEstadosActividad':
                      const logObjectEE = JSON.parse(log.description)
                      
                      // Objeto de mapeo para idEstado
                      const estadoMape = {
                        1: "No Presentado",
                        2: "Aprobado",
                        3: "Suspendido",
                        4: "Convalidado D",
                        5: "Exento",
                        6: "Convalidado C",
                        10: "Recalificado"
                      };
                    
                      // Obtén el string descriptivo del estado
                      const estadoDescripcioe  = estadoMape[logObjectEE.idEstado] || "Estado desconocido";

                      return (
                        <p
                          key={log.id}
                          className="rounded p-3 mt-3 "
                          style={{
                            marginRight: '30px',
                            marginLeft: '30px',
                            backgroundColor: '#D8B05C',
                            color: 'white',
                          }}
                        >
                          {log.formattedDate} - El usuario {log.nombre} ha editado el estado de la actividad {logObjectEE.idMatriculal} a {estadoDescripcioe}.
                        </p>
                      );
                    case 'bajaMatricula':
                      return (
                        <p
                          key={log.id}
                          className="rounded p-3 mt-3 "
                          style={{
                            marginRight: '30px',
                            marginLeft: '30px',
                            backgroundColor: '#69BDDF',
                            color: 'white',
                          }}
                        >
                          {log.formattedDate} - El usuario {log.nombre} ha cambiado el estado a
                          : {log.description}{' '}
                        </p>
                      );
                    // case 'otro':
                    //   const logCrearMatricula = JSON.parse(log.description);
                      
                    //   // Extraer los valores de idBorrados, idEditados, idEditados2 y nuevos
                    //   const { comentarios:coment } = logCrearMatricula
                    //   return (
                    //     <p
                    //       key={log.id}
                    //       className="rounded p-3 mt-3 "
                    //       style={{
                    //         marginRight: '30px',
                    //         marginLeft: '30px',
                    //         backgroundColor: '#69BDDF',
                    //         color: 'white',
                    //       }}
                    //     >
                    //       {/* {log.formattedDate} - El usuario {log.nombre} creó la matrícula con el siguiente comentario
                    //       : {log.DESCRIPTION}{' '} */}
                    //       {
                    //         coment !== '' && coment !== '""'
                    //           ? `${log.formattedDate} - El usuario ${log.nombre} creó la matrícula con el siguiente comentario: ${coment}.`
                    //           : `${log.formattedDate} - El usuario ${log.nombre} creó la matrícula.`
                    //       }
                          
                    //     </p>
                    //   );
                    case 'borrarRecibo':
                      return (
                        <p
                          key={log.id}
                          className="rounded p-3 mt-3 "
                          style={{
                            marginRight: '30px',
                            marginLeft: '30px',
                            backgroundColor: '#A65454',
                            color: 'white',
                          }}
                        >
                          {log.formattedDate} - El usuario {log.nombre} ha borrado los recibos con ID
                          : {log.description}{' '}
                        </p>
                      );
                    case 'confirmarPeticion':
                      const logg = JSON.parse(log.description);
                      
                      // Extraer los valores de idBorrados, idEditados, idEditados2 y nuevos
                      const {comentarios, idBorrados, idEditados, idEditados2, nuevos} = logg
                        
                      // Comprueba si alguno de los arrays tiene una longitud mayor a 0
                      if (idBorrados.length > 0 || idEditados.length > 0 || idEditados2.length > 0 || nuevos.length > 0) {
                        return (
                          <p
                            key={log.id}
                            className="rounded p-3 mt-3"
                            style={{
                              marginRight: '30px',
                              marginLeft: '30px',
                              backgroundColor: '#D8B05C',
                              color: 'white',
                            }}
                          >
                            {log.formattedDate} - El usuario {log.nombre}
                            {idBorrados.length > 0 && (
                              <>
                                {' ha borrado los recibos con ID: '}
                                {idBorrados.map((recibo, index) => (
                                  <span key={index}>
                                    {recibo},
                                  </span>
                                ))}
                              </>
                            )}
                            {idEditados2.length > 0 && (
                              <>
                                {' ha editado los estados de los recibos con ID: '}
                                {idEditados2.map((recibo, index) => (
                                  <span key={index}>
                                    {recibo.id},
                                  </span>
                                ))}
                              </>
                            )}
                            {idEditados.length > 0 && (
                              <>
                                {' ha editado la forma de pago de los recibos con ID: '}
                                {idEditados.map((recibo, index) => (
                                  <span key={index}>
                                    {recibo.id},
                                  </span>
                                ))}
                              </>
                            )}
                            {nuevos.length > 0 && (
                              <>
                                {' ha añadido los recibos con concepto: '}
                                {nuevos.map((recibo, index) => (
                                  <span key={index}>
                                    {recibo.Concepto}
                                  </span>
                                ))}
                              </>
                            )}
                          </p>
                        );
                      }

                      return (
                        <p
                          key={log.id}
                          className="rounded p-3 mt-3"
                          style={{
                            marginRight: '30px',
                            marginLeft: '30px',
                            backgroundColor: '#69BDDF',
                            color: 'white',
                          }}
                        >
                          {log.formattedDate} - El usuario {log.nombre} ha hecho cambios. Comentarios: {comentarios}
                        </p>
                      )
                      
                    default:
                      return null; // Otra acción no especificada
                  }
                })()
              }
            </div>
          ))
        }
      </div>
    </>
  );
}

export default Histórico;