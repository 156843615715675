import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Tooltip, } from 'reactstrap';
import PropTypes from 'prop-types';
import { AuthContext } from '../../../context/authContext';
import ModalOptions  from '../../../layouts/modals/ModalOptions';

const Acciones = ({isNoPresencial, curso, comentarios, modulos, activity, hoursCounter, idEditados2, idEditados, idBorrados, nuevos}) => {

  const navigate = useNavigate();
    const userSession = JSON.parse(sessionStorage.getItem('user'));
    const enrollmentSession = JSON.parse(localStorage.getItem('enrollment'));
    const [balance, setBalance] = useState(-1);
    const { userOU } = useContext( AuthContext );
const [dataApi, setDataApi] = useState([]);
const [tooltipBalance, setTooltipBalance] = useState({ tooltipOpen: false });
const [isOpen, setIsOpen] = useState(false)
const [isToolTipHoursOpened, setIsToolTipHoursOpened] = useState(false)
const [url, setUrl] = useState('')
const [raw, setRaw] = useState({})



useEffect(() => {
    fetch(
        `${process.env.REACT_APP_NODE_API}consultarBalance?headquarter=${enrollmentSession.headquarter}&id=${enrollmentSession.id}&userEmail=${userSession.profileObj.email}`,
        {
          method: 'get',
          headers: {
            Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('user')).tokenId}`,
            'content-type': 'application/json',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0'
          },
        },
      )
        .then((response) => response.json())
        .then((actualData) => {
          setBalance(parseFloat(actualData.Balance));
        })
        .catch((error) => {
          sessionStorage.setItem('apiError', error);
          sessionStorage.removeItem('user');
          window.location.reload();
        });
 
    fetch(
      `${process.env.REACT_APP_NODE_API}detalleMatricula?headquarter=${enrollmentSession.headquarter}&id=${enrollmentSession.id}&userEmail=${userSession.profileObj.email}`,
      {
        method: 'get',
        headers: {
          Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('user')).tokenId}`,
          'content-type': 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        },
      },
    )
      .then((response) => response.json())
      .then((actualData) => {
      
        setDataApi(actualData);
      })
      .catch((error) => {
        sessionStorage.setItem('apiError', error);
        sessionStorage.removeItem('user');
        window.location.reload();
      });

  }, []);
  const updateComment = () => {

    const rawInfo = JSON.stringify({
      headquarter: enrollmentSession.headquarter,
      userEmail: userSession.profileObj.email,
      numMatricula: enrollmentSession.id,
      comment: comentarios
    });

    fetch(`${process.env.REACT_APP_NODE_API}agregarComentario`, {
      method: 'POST',
      body: rawInfo,
      redirect: 'follow',
      mode: 'cors',
      cache: 'no-store',
      referrerPolicy: 'no-referrer',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('user')).tokenId}`,
        'content-type': 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '0'
      },
    })
      .then((response) => response.json())
      .then(() => {
        
        // Aquí puedes realizar otras acciones después de setIsShown(true) si es necesario.
        window.location.reload();
      });

  }

  const updateModules= () => {
    const rawInfo = JSON.stringify({
      headquarter: enrollmentSession.headquarter,
      userEmail: userSession.profileObj.email,
      numMatricula: enrollmentSession.id,
      modulos,
      activity
    });

    fetch(`${process.env.REACT_APP_NODE_API}actualizarModulos`, {
      method: 'POST',
      body: rawInfo,
      redirect: 'follow',
      mode: 'cors',
      cache: 'no-store',
      referrerPolicy: 'no-referrer',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('user')).tokenId}`,
        'content-type': 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '0'
      },
    })
      .then((response) => response.json())
  }



  const handleOnClick = () => {
    navigate(-1);
  };


   // Función para cambiar el estado (Sede - Secretaria)
   const handleState = (state) => {
    setIsOpen(true)
    const rawInfo = {
      state,
      headquarter: enrollmentSession.headquarter,
      userEmail: userSession.profileObj.email,
      numMatricula: enrollmentSession.id,
      nombre: dataApi[0].sNombre,
      studentEmail: enrollmentSession.email,
      idDelegacion: enrollmentSession.headquarter === 'CEDESCA' ? userOU.idCedesca : userOU.idCntec,
      comentarios,
      curso
    };
    setRaw(rawInfo)
    setUrl(`${process.env.REACT_APP_NODE_API}cambiarEstado`)
    
  };

  const handleSede = () => {  
    handleState('6');
  };
  const handleSecretaria = () => {  
    handleState('5');
  };

  const toggleTooltipBalance = () => {
    setTooltipBalance((prev) => ({
      tooltipOpen: !prev.tooltipOpen,
    }));
  };
  const toggleHours = () => {
    setIsToolTipHoursOpened(!isToolTipHoursOpened)
}

  // Función para confirmar la petición
const handleConfirm = () => {
  setIsOpen(true)
  const rawInfo = {
    headquarter: enrollmentSession.headquarter,
    userEmail: userSession.profileObj.email,
    numMatricula: enrollmentSession.id,
    nombre: dataApi[0].sNombre,
    emailAlumno: enrollmentSession.email,
    idDelegacion: enrollmentSession.headquarter === 'CEDESCA' ? userOU.idCedesca : userOU.idCntec,
    comentarios,
    idBorrados,
    idEditados,
    idEditados2,
    nuevos,
    curso
  };
  setRaw(rawInfo)
  setUrl(`${process.env.REACT_APP_NODE_API}confirmarPeticion`)
};
 
    return (
        <>
          {isOpen && <ModalOptions header="¿Desea realizar estos cambios?" url={url} raw={raw} load={setIsOpen} text1='Cancelar' color1='danger' text2='Aceptar' color2='primary' comment={ comentarios.length > 0 ? updateComment : ''} module={updateModules} />}
            {dataApi.length > 0 && dataApi[0].estado === 'Activa' && (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button className="float-end me-1" onClick={handleOnClick}>
                        Atrás
                    </Button>
                    {userOU.centro === 'SUPERADMIN' || userOU.centro === 'CNTEC Girona' || userOU.centro === 'CEDESCA' || userOU.centro === 'CNTEC Sevilla'  || userOU.centro === 'SECRETARÍA' ?  
                    
                    <Button className="float-end" color="success" disabled= {(isNoPresencial && hoursCounter > 2000) || (comentarios.length === 0 && nuevos.length === 0 && idEditados2.length===0 && idEditados.length===0 && idBorrados.length === 0) } onClick={handleConfirm}>
                  
                        
                    
                        Guardar cambios
                    </Button>  : 
                    <Button className="float-end" color="success" onClick={updateComment} disabled = {comentarios.length === 0}>
                     
                    
                        Enviar comentario
                    </Button>}

                    
                </div>
            )}
            {dataApi.length > 0 && dataApi[0].estado === 'Revisar delegación' && (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                    <Button className="float-end me-1" onClick={handleOnClick}>
                        Atrás
                    </Button>
                    
                    {userOU.centro === 'SUPERADMIN' || userOU.centro === 'CNTEC Girona' || userOU.centro === 'CEDESCA' || userOU.centro === 'CNTEC Sevilla' ?  
                 
                      
                      <Button className="float-end" color="success" onClick={updateComment} disabled = {comentarios.length === 0}>
                    Enviar comentario
                </Button>
                   
                    
                     :

                      
                      <Button className="float-end" color="success" onClick={handleSecretaria} disabled= {(isNoPresencial && hoursCounter > 2000) || (comentarios.length === 0 && nuevos.length === 0 && idEditados2.length===0 && idEditados.length===0 && idBorrados.length === 0) }>
                     Confirmar revisión
                     </Button>
                     
                      }
                    
                </div>
            )}
            {dataApi.length > 0 && dataApi[0].estado === 'Revisar secretaria' && (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button className="float-end me-1" onClick={handleOnClick}>
                        Atrás
                    </Button>
                    {userOU.centro === 'SUPERADMIN' || userOU.centro === 'CNTEC Girona' || userOU.centro === 'CEDESCA' || userOU.centro === 'CNTEC Sevilla' || userOU.centro === 'SECRETARÍA' ?
                    <>
                    <span id="toolTipHours">
                      {comentarios.length === 0 && nuevos.length === 0 && idEditados2.length===0 && idEditados.length===0 && idBorrados.length === 0 ? <Tooltip placement="top" isOpen={isToolTipHoursOpened} target="toolTipHours" toggle={toggleHours}>
                          Realice cambios o escriba un comentario antes de rechazar.
                        </Tooltip> : ''}
                    
                    <Button className="float-end" color="warning" onClick={handleSede} disabled={comentarios.length === 0 && nuevos.length === 0 && idEditados2.length===0 && idEditados.length===0 && idBorrados.length === 0}>
                    Rechazar solicitud
                    </Button>
                    </span>
                    
                    <div id="Test">
                   
                      
                    <Button className="float-end" color="success" onClick={handleConfirm} disabled={balance < 0 ||(isNoPresencial && hoursCounter > 2000)}>
                    Confirmar solicitud
                    </Button>
                    </div>
                    {balance < 0 ?
                    <Tooltip
                      placement="top"
                      isOpen={tooltipBalance.tooltipOpen}
                      target="Test"
                      toggle={toggleTooltipBalance}
                      >
                      No es posible confirmar la solicitud porque el alumno tiene un balance negativo
                      de {balance} €
                    </Tooltip>
                     : 
                     ''}
                    </>
                     : 
                     
                     <Button className="float-end" color="success" onClick={updateComment} disabled = {comentarios.length === 0}>
                     Enviar comentario
                 </Button>
                      }
                </div>
            )}
            {dataApi.length > 0 && dataApi[0].estado === 'Baja' && (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button className="float-end me-1" onClick={handleOnClick}>
                        Atrás
                    </Button>
                 
                    <Button className="float-end" color="success" onClick={updateComment} disabled = {comentarios.length === 0}>
                        Enviar comentario
                    </Button>
                </div>
            )}
            {dataApi.length > 0 && dataApi[0].estado === 'Pendiente baja' && (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button className="float-end me-1" onClick={handleOnClick}>
                        Atrás
                    </Button>
                
                    <Button className="float-end" color="success" onClick={updateComment} disabled = {comentarios.length === 0}>
                        Enviar comentario
                    </Button>
                    
                </div>
            )}      
         
        </>
    )
}
Acciones.propTypes = {
    isNoPresencial: PropTypes.any,
    curso: PropTypes.any,
    comentarios: PropTypes.any,
    modulos: PropTypes.any,
    activity: PropTypes.any,
    hoursCounter: PropTypes.any,
    idBorrados: PropTypes.any,
    idEditados: PropTypes.any,
    idEditados2: PropTypes.any,
    nuevos: PropTypes.any,
  };
export default Acciones