import { useState } from 'react'
import PropTypes from 'prop-types'
import { ModalBody, ModalFooter, Modal, Button } from 'reactstrap'


const ModalOptions = (props) => {

    const [modalState, setModalState] = useState(true)
    const handleModalState = () => {
        if (modalState === true) {
            setModalState(false)
            props.load(false)
        } else {
            setModalState(true)
        }
    }

    const handleModalAccept = () => {
        props.load(true)
        setModalState(false)
        
        const r = JSON.stringify(props.raw)

        fetch(`${props.url}`, {
            method: 'POST',
            body: `${r}`,
            redirect: 'follow',
            mode: 'cors',
            cache: "no-store",
            referrerPolicy: "no-referrer",
            credentials: "same-origin",
            headers: {
                'Authorization': `Bearer ${JSON.parse(sessionStorage.getItem('user')).tokenId}`,
                'content-type': 'application/json',
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': '0'
            }
        }
        )
            .then((response) => response.json())
            .then(() => {
                
               
                if(props.module !== undefined){
                    props.module()
                    
                }
    
                if (props.comment !== undefined && props.comment !== ''){
                    props.comment()
                }
                
                props.load(false)
                window.location.reload()
            })
            .catch(error => {
                sessionStorage.setItem('apiError', error)
                sessionStorage.removeItem('user')
                window.location.href = '/';
            })



    }
    return (
        <Modal isOpen={modalState} centered>
            <ModalBody>
                <h4 className="text-center mt-3">{props.header}</h4>
            </ModalBody>
            <ModalFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button color={props.color1} onClick={handleModalState}>
                    {props.text1}
                </Button>
                <Button color={props.color2} onClick={handleModalAccept}>
                    {props.text2}
                </Button>
            </ModalFooter>
        </Modal>
    )
}
ModalOptions.propTypes = {
    header: PropTypes.any,
    load: PropTypes.any,
    url: PropTypes.any,
    raw: PropTypes.any,
    text1: PropTypes.any,
    color1: PropTypes.any,
    text2: PropTypes.any,
    color2: PropTypes.any,
    module: PropTypes.any,
    comment: PropTypes.any

};
export default ModalOptions