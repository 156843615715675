import { Navigate } from 'react-router-dom';
import NuevaMatricula from '@asubarrocacedetec/auto-mat';
import ListadoUsuarios from '../views/usuarios/ListadoUsuarios';
import DetallesUsuarios from '../views/usuarios/DetallesUsuarios';
import ServicioIntercambio from '../views/serviciointercambio/ServicioIntercambio';
import Actas from '../views/actas/Actas';
import Boletines from '../views/boletines/Boletines';
import Logs from '../views/logs/Logs';
import Solicitudes from '../views/matriculas/Solicitudes';
import Revision from '../views/matriculas/Revision';
import NuevoAlumno from '../views/usuarios/NuevoAlumno';
import MotorCalculo from '../views/motorcalculo/MotorCalculo';
import SuplantacionIdentidad from '../views/suplantacionidentidad/SuplantacionIdentidad';

import PrivateRoute from '../layouts/hocs/PrivateRoute';
import { USER_ROLES } from '../helpers/constants';

/*****Routes******/

const ThemeRoutes = [
  {
    path: '/',
    exact: true,
    element: <Navigate to="/alumnos" />,
  },
  {
    path: '/alumnos',
    exact: true,
    element: <ListadoUsuarios />,
  },
  {
    path: '/alumnos/:empresaId/:studentId/detalles',
    exact: true,
    element: <DetallesUsuarios />,
  },
  {
    path: '/servicio-de-intercambio',
    exact: true,
    element: (
      <PrivateRoute roles={[USER_ROLES.SUPERADMIN, USER_ROLES.SEDE, USER_ROLES.SECRETARIA]}>
        <ServicioIntercambio />
      </PrivateRoute>
    ),
  },
  {
    path: '/actas',
    exact: true,
    element: (
      <PrivateRoute roles={[USER_ROLES.SUPERADMIN, USER_ROLES.SEDE, USER_ROLES.SECRETARIA]}>
        <Actas />
      </PrivateRoute>
    ),
  },
  {
    path: '/boletines',
    exact: true,
    element: (
      <PrivateRoute roles={[USER_ROLES.SUPERADMIN, USER_ROLES.SEDE, USER_ROLES.SECRETARIA]}>
        <Boletines />
      </PrivateRoute>
    ),
  },
  {
    path: '/matriculas/nueva',
    exact: true,
    element: (
      // TODO: Revisar las permisiones de esta ruta con Javi.
      <PrivateRoute
        roles={[
          USER_ROLES.SUPERADMIN,
          USER_ROLES.DELEGACION,
          USER_ROLES.SEDE,
          USER_ROLES.SECRETARIA,
        ]}
      >
        <NuevaMatricula
          variant={process.env.REACT_APP_ORIGIN}
          deployment={process.env.REACT_APP_DEPLOYMENT}
        />
      </PrivateRoute>
    ),
  },
  {
    path: '/solicitudes-matricula',
    exact: true,
    element: <Solicitudes />,
  },
  {
    path: '/solicitudes-matricula/:empresaId/:matriculaId/revision/',
    exact: true,
    element: <Revision />,
  },
  {
    path: '/logs',
    exact: true,
    element: (
      <PrivateRoute roles={[USER_ROLES.SUPERADMIN]}>
        <Logs />
      </PrivateRoute>
    ),
  },
  {
    path: '/nuevo-alumno',
    exact: true,
    // TODO: Revisar las permisiones de esta ruta con Javi.
    element: <NuevoAlumno />,
  },
  {
    path: '/calculo-notas',
    exact: true,
    element: (
      <PrivateRoute roles={[USER_ROLES.SUPERADMIN]}>
        <MotorCalculo />
      </PrivateRoute>
    ),
  },
  {
    path: '/suplantacion-identidad',
    exact: true,
    element: (
      <PrivateRoute roles={[USER_ROLES.SUPERADMIN]}>
        <SuplantacionIdentidad />
      </PrivateRoute>
    ),
  },
];

export default ThemeRoutes;
