import { React, useContext, useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import { GoogleLogin } from '@react-oauth/google';
//import { gapi } from 'gapi-script';
import { useNavigate } from 'react-router-dom';
import { decodeToken } from 'react-jwt';
import ModalSesion from './ModalSesion';
import { AuthContext } from '../../context/authContext';
import logo from '../../assets/images/logos/logo.png';
import LeftBg from '../../layouts/svgs/LeftBg'
import RightBg from '../../layouts/svgs/RightBg';
import '../../assets/scss/style.scss';

const LoginForm = (props) => {
  const { loginGoogle } = useContext(AuthContext);
  const [isWhitelisted, setIsWhitelisted] = useState(true);
  // const [auth, setAuth] = useState(null);
  const navigate = useNavigate();
  // const clientId = process.env.REACT_APP_CLIENT_ID;
  const emails = process.env.REACT_APP_WHITELIST.split(',');

  // useEffect(() => {

  //   const scopes = 'https://www.googleapis.com/auth/admin.directory.group.readonly https://www.googleapis.com/auth/admin.directory.group';

    
  //   window.gapi.load('auth2', () => {
  //     window.gapi.auth2.init({
  //       'client_id': clientId, // 101741341344156547837-monitor-347120.iam.gserviceaccount.com
  //       'scope': scopes,
  //       'immediate': true,
  //       'response_type': 'token id_token'
  //     })
  //     .then(() => {
  //       setAuth( window.gapi.auth2.getAuthInstance() );
  //     });
  //   });

  //   (window.gapi.auth2)

  // }, []);

  // useEffect(() => {
  //   if(auth !== null) {

  //     (auth)   

  //   }
  // },[auth])

  const responseGoogle = async ({ credential }) => {
    // Obtenemos loss datos del token
    const decodedToken = decodeToken(credential);
    const { email } = decodedToken;

    // Revisamos la whitelist
    if (!emails.includes(email)) {
      setIsWhitelisted(false);
    } else {

      await loginGoogle({ tokenId: credential, profileObj: decodedToken });

      // Verificamos si existe alguna ruta anterior antes de hacer login
      if (localStorage.getItem('lastPath')) {
        navigate(localStorage.getItem('lastPath'));
      } else {
        navigate('/alumnos');
      }
    }
  };

  return (
    <div className="loginBox">
      {props.sesionExpired === 'true' ? (
        <ModalSesion body="Por favor, vuelva a iniciar sesión." header="Sesión expirada" />
      ) : (
        ''
      )}
      {isWhitelisted === false ? (
        <ModalSesion body="No puedes acceder a este sitio." header="Cuenta incorrecta." />
      ) : (
        ''
      )}
      <LeftBg/>
      <RightBg/>
      <Container fluid className="h-100">
        <Row className="justify-content-center align-items-center h-100">
          <Col lg="12" className="loginContainer text-center">
            <Card color="white">
              <img src={logo} alt="Imagen corporativa" width="300" className="mx-auto" />
              <CardBody
                className="p-4 m-1"
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
              >
                <small className="pb-4 d-block">
                  Acceso al panel de administración utilizando una cuenta de <b>cedetecgroup.com</b>
                </small>
                <GoogleLogin
                  onSuccess={responseGoogle}
                  onError={() => {
                    ('Login Failed');
                  }}
                  useOneTap
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

LoginForm.propTypes = {
  sesionExpired: PropTypes.string,
};

export default LoginForm;
