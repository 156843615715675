import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Navbar,
  Nav,
  NavbarBrand,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import * as Icon from 'react-feather';
import { AuthContext } from '../../context/authContext';
import logo from '../../assets/images/logos/logo.png';
import { ToggleMiniSidebar, ToggleMobileSidebar } from '../../store/customizer/CustomizerSlice';
import ProfileDD from './ProfileDD';

const Header = () => {
  const { logoutGoogle, userOU } = useContext(AuthContext);
  const isDarkMode = useSelector((state) => state.customizer.isDark);
  const topbarColor = useSelector((state) => state.customizer.topbarBg);
  const dispatch = useDispatch();
  const user = JSON.parse(sessionStorage.getItem('user')).profileObj;
  const adminUser = JSON.parse(sessionStorage.getItem('user')).adminProfileObj ?? null;
  const { impersonating = false } = JSON.parse(sessionStorage.getItem('user'));
  const email = impersonating ? adminUser.email : user.email;
  const impersonateEmail = impersonating ? user.email : '';
  const picture = (impersonating ? adminUser.picture : user.picture) ?? '/images/noUserPicture.jpg';
  const name = impersonating ? adminUser.name : user.name;
  const impersonateName = impersonating ? user.nombre : '';

  const navigate = useNavigate();

  const logout = () => {
    logoutGoogle();
    navigate('/');
  };

  const handleEndImpersonation = () => {
    const currentUserSession = JSON.parse(sessionStorage.getItem('user') ?? {});
    const { adminProfileObj, tokenId } = currentUserSession;
    const newUserSession = {
      profileObj: adminProfileObj,
      tokenId,
      impersonating: false,
    };

    sessionStorage.setItem('user', JSON.stringify(newUserSession));
    window.location.href = '/';
  };

  return (
    <Navbar
      color={topbarColor}
      dark={!isDarkMode}
      light={isDarkMode}
      expand="lg"
      className="topbar"
    >
      {/******************************/}
      {/**********Toggle Buttons**********/}
      {/******************************/}
      <div className="d-flex align-items-center">
        <Button
          color={topbarColor}
          className="d-none d-lg-block"
          onClick={() => dispatch(ToggleMiniSidebar())}
        >
          <i className="bi bi-list" />
        </Button>
        <NavbarBrand href="/alumnos" className="d-sm-block d-lg-none">
          <img src={logo} alt="Logo corporativo" width="30" />
        </NavbarBrand>
        <Button
          color={topbarColor}
          className="d-sm-block d-lg-none"
          onClick={() => dispatch(ToggleMobileSidebar())}
        >
          <i className="bi bi-list" />
        </Button>
      </div>

      {/******************************/}
      {/**********Left Nav Bar**********/}
      {/******************************/}

      <Nav className="me-auto d-none d-lg-flex" navbar>
        {/* <NavItem>
          <Link to="/starter" className="nav-link">
            Starter
          </Link>
        </NavItem> */}
      </Nav>

      <div className="d-flex">
        {/******************************/}
        {/**********Profile DD**********/}
        {/******************************/}
        <UncontrolledDropdown>
          <DropdownToggle color={topbarColor}>
            <img src={picture} alt="profile" className="rounded-circle" width="30" />
            {impersonating && (
              <div
                style={{
                  position: 'absolute',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  top: 2,
                  right: 2,
                  background: '#ee9d01',
                  borderRadius: 24,
                  width: 20,
                  height: 20,
                }}
              >
                <Icon.Users color="white" width={12} />
              </div>
            )}
          </DropdownToggle>
          <DropdownMenu className="ddWidth">
            <ProfileDD
              name={name}
              impersonateName={impersonateName}
              email={email}
              impersonateEmail={impersonateEmail}
              image={picture}
              userOU={userOU}
              impersonating={impersonating}
            />
            <div className="py-2 px-3 d-flex justify-content-between">
              <Button color="danger" size="sm" onClick={logout}>
                Desconectar
              </Button>
              {impersonating && (
                <Button color="warning" size="sm" onClick={handleEndImpersonation}>
                  Fin suplantación
                </Button>
              )}
            </div>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </Navbar>
  );
};

export default Header;
