import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types'
import Header from './header/Header';
import Sidebar from './sidebars/vertical/Sidebar';
import '../assets/scss/style.scss';
import { AuthContext } from '../context/authContext';

if(process.env.REACT_APP_DEPLOYMENT === 'local'){
import('../assets/scss/stylelocal.scss');
}else if(process.env.REACT_APP_DEPLOYMENT === 'test'){
import('../assets/scss/styletest.scss');
}else{
import('../assets/scss/style.scss');
}

const FullLayout = ({ children }) => {

  const customizerToggle = useSelector((state) => state.customizer.customizerSidebar);
  const toggleMiniSidebar = useSelector((state) => state.customizer.isMiniSidebar)
  const showMobileSidebar = useSelector((state) => state.customizer.isMobileSidebar);
  const LayoutHorizontal = useSelector((state) => state.customizer.isLayoutHorizontal);
  const isFixedSidebar = useSelector((state) => state.customizer.isSidebarFixed);
  const { getUserOU } = useContext( AuthContext );


  // Cada vez que se recarga cualquier página, se llama a obtener OU para
  // asegurar en todo momento los permisos que tiene el usuario
  useEffect(() => {
    getUserOU();
  },[])

  return (
    <main>
      <div
        className={`pageWrapper d-md-block d-lg-flex ${toggleMiniSidebar ? 'isMiniSidebar' : ''}`}
      >
        {/******** Sidebar **********/}

        <aside className={`sidebarArea ${showMobileSidebar ? 'showSidebar' : ''}`}>
          <Sidebar />
        </aside>
        {/********Content Area**********/}

        <div className='contentArea fixedTopbar'>
          {/********header**********/}
          <Header />
          {/********Middle Content**********/}
          <Container fluid className="p-4 boxContainer">
            <div className={isFixedSidebar && LayoutHorizontal ? 'HsidebarFixed' : ''}>
              {children}
            </div>
            {showMobileSidebar || customizerToggle ? <div className="sidebarOverlay" /> : ''}
          </Container>
        </div>
      </div>
    </main >
  );
};
FullLayout.propTypes = {
  children: PropTypes.node,
};
export default FullLayout;
