import React, { useState, useEffect, useContext } from 'react';
import { Collapse, Table, Row, Col} from 'reactstrap';
import PropTypes from 'prop-types';
import Actividad from './Actividad';
import { AuthContext } from '../../../context/authContext';



const Desplegable = (props) => {

// Preparamos variables necesarias
const [collapse, setCollapse] = useState(false);
const [isParentChecked, setIsParentChecked] = useState(false);
const [completed, setIsCompleted] = useState(false);
const [isWasted, setIsWasted] = useState(false);
const [activityStateList, setActivityStateList] = useState([]);
const [convoStateList, setConvoStateList] = useState([]);
const localEnrollment = JSON.parse(localStorage.getItem('enrollment'));
const { userOU} = useContext( AuthContext );

  // Efectos
  useEffect(() => {

    let cont= 0;
    let contConv= 0;

    props.activity[props.keyArray].forEach( (actividad) => {

      //Comprobar si todas las actividades del módulo tienenen las convocatorias gastadas o están aprobadas
      if (actividad.Nota === 1) {
        cont++;
      }

      if (parseInt(actividad.convocatorias,10) === 5){
        contConv++;
      }

    });

    if ( cont === props.activity[props.keyArray].length) {
      setIsCompleted(true);
    }
    if (contConv === props.activity[props.keyArray].length ) {
      setIsWasted(true)
    }

  }, []);

  // Peticiones a la API
  useEffect(() => {

    fetch(`${process.env.REACT_APP_NODE_API}cargarEstadosActividad?headquarter=${ localEnrollment.headquarter }`, {
      method: 'GET',
      redirect: 'follow',
      mode: 'cors',
      cache: "no-store",
      referrerPolicy: "no-referrer",
      credentials: "same-origin",
      headers: {
          'Authorization': `Bearer ${JSON.parse(sessionStorage.getItem('user')).tokenId}`,
          'content-type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((actualData) => {
      const idsToFilter = [0,1,2, 3, 5,];

    const filteredData = actualData.filter(item => idsToFilter.includes(item.Id));
      setActivityStateList([ { Id: -1, Valor: '' }, ...filteredData ]);
    });

    fetch(`${process.env.REACT_APP_NODE_API}cargarListaConvocatorias?headquarter=${ localEnrollment.headquarter }`, {
      method: 'GET',
      headers: {
          'Authorization': `Bearer ${JSON.parse(sessionStorage.getItem('user')).tokenId}`,
          'content-type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((actualData) => {
      setConvoStateList([ { Id: -1, Valor: '' }, ...actualData ]);
    });

  },[]);

  // Efecto que marca o desmarca el padre según sus hijos
  useEffect(() => {

    let arrCount = 0;

    // Revisamos hijo por hijo si está marcado
    props.array.forEach( obj => {

      const value = `${obj.modulo} ${obj.descripcion}`;

      if( props.modulos.includes( value )) {
        arrCount++;
      }

    })

    // Si están todos marcados, lo marcamos
    if( props.array.length === arrCount ) {
      setIsParentChecked( true );
    } else {
      setIsParentChecked( false );
    }

  },[props.modulos]);

  const toggle = (e) => {
    if (e.target.id === "") {
      setCollapse(!collapse);
    }
  };

  const handleParentCheckboxChange = () => {
    
    setIsParentChecked(!isParentChecked);
    let tFinal = 0;
    let hFinal = 0;
  
    props.array.forEach((obj) => {

      const value = `${obj.modulo} ${obj.descripcion}`;
  
      if (!isParentChecked) {
        if (!props.modulos.includes(value)) {
          const newArr = props.modulos;
          newArr.push(value);
          props.setModulos([...newArr]);
          if (!obj.descripcion.includes('Formación en el centro de trabajo') && !obj.descripcion.includes('Formación en centros de trabajo')) {
            hFinal += parseInt(obj.horas, 10);
          }
          tFinal += obj.precio;
        }
      } else {
        const index = props.modulos.indexOf(value);
        if (index !== -1) {
          const newArr = props.modulos;
          newArr.splice(index, 1);
          props.setModulos([...newArr]);
          if (!obj.descripcion.includes('Formación en el centro de trabajo') && !obj.descripcion.includes('Formación en centros de trabajo')) {
            hFinal -= parseInt(obj.horas, 10);
          }
          tFinal -= obj.precio;
        }
      }

    });
  
    props.setHoursCounter(props.hoursCounter + hFinal);
    props.setTotal(props.total + tFinal);

  };

    return (
        <div key={ props.keyArray }>
        <div className="border-bottom rounded-top mb-2 px-2 py-2 pe-auto" onClick={ toggle } style={{ backgroundColor: '#eef5f9', cursor: 'pointer' }}>
          <Row>
            <Col sm="1">
            {props.dataApi.length > 0 ? <input
                type="checkbox"
                checked={ isParentChecked }
                onChange={ handleParentCheckboxChange }
                id={ props.activity[props.keyArray][0].nombre }
                disabled={ completed || isWasted || props.estado || props.dataApi[0].estado === 'Revisar secretaria' || userOU.centro === 'ORIENTACIÓN'}
              /> : ''}
              
            </Col>
            <Col sm="9">
              <b>{ props.activity[props.keyArray][0].nombre }{ completed ? ' - Completado' : '' } { isWasted ? ' - Límite de convocatorias superado' : '' }</b>
            </Col>
            <Col sm="2"><b style={props.activity[props.keyArray][0].curso === 'PRIMERO' ? {color:'green'}: {color:'orange'}}>{props.activity[props.keyArray][0].curso === 'PRIMERO' ? 'PRIMER AÑO' : 'SEGUNDO AÑO'}</b></Col>

          </Row>
        </div>
        <Collapse isOpen={ collapse }>
          <Table responsive borderless>
            <thead>
              <tr>
                <th>Código</th>
                <th>Descripción</th>
                <th>Convocatorias</th>
                <th>Horas</th>
                <th>Precio</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {
                props.array.map( (obj) => (
                  <Actividad
                    idMatricula={ props.idMatricula }
                    obj={ obj }
                    activityStateList={ activityStateList }
                    convoStateList={ convoStateList }
                    hoursCounter={ props.hoursCounter }
                    setHoursCounter={ props.setHoursCounter }
                    total={ props.total }
                    setTotal={ props.setTotal }
                    modulos={ props.modulos }
                    setModulos={ props.setModulos }
                    estado={ props.estado }
                    dataApi={props.dataApi}
                  />
                ))
              }
            </tbody>
          </Table>
        </Collapse>
      </div>
    )
}

Desplegable.propTypes = {
    array: PropTypes.any,
    activity: PropTypes.any,
    keyArray: PropTypes.any,
    modulos: PropTypes.any,
    setModulos: PropTypes.any,
    setHoursCounter: PropTypes.any,
    setTotal: PropTypes.any,
    total: PropTypes.any,
    hoursCounter: PropTypes.any,
    estado: PropTypes.any,
    idMatricula: PropTypes.any,
    dataApi: PropTypes.any
  };

export default Desplegable