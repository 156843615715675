import React, { useState } from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';
import PropTypes from 'prop-types';

const ComponentCard = ({ children, title, subtitle, dropdown, handleClick, name }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(!dropdownOpen);
  return (
    <Card color="white">
      <CardTitle tag="h4" className="border-bottom px-4 py-3 mb-0">
        {dropdown === true ? (
          <Dropdown nav isOpen={dropdownOpen} toggle={toggle.bind(null)} className="list-unstyled">
            <DropdownToggle nav caret>
              {title}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={handleClick}>
                Ir al listado
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        ) : (
          <>{title}</>
        )}
        {name ? <span className="float-end mb-0">{name}</span> : ''}
      </CardTitle>

      <CardBody className="p-4">
        <CardSubtitle className="text-muted mb-3">{subtitle || ''}</CardSubtitle>
        <div>{children}</div>
      </CardBody>
    </Card>
  );
};

ComponentCard.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.node,
  dropdown: PropTypes.bool,
  handleClick: PropTypes.any,
  name: PropTypes.string,
};

export default ComponentCard;
